/// Menu
import React, { Component, useState } from "react";

import MetisMenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, } from "react-router-dom";
// import { LogoutUser } from '../../../services/AuthService'
import LogoutPage from '../../layouts/nav/Logout'
import { Cart } from "../../../icons/default/Cart";
import kiosk from "../../../icons/flaticon/kiosk.png"
import eports from "../../../icons/flaticon/export.png"
import { TbFileExport } from 'react-icons/tb';
import { SlScreenTablet } from 'react-icons/sl';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }

}

class SideBar extends Component {


  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");


    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }

  state = {
    loveEmoji: false,

  };

  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    /// Active menu
    let dashBoard = [
      "",
    ];
    let tests =
      ["tests"]
      ;
    let users = [
      "users"
    ];
    let kiosks = [
      "kiosks"
    ]
    let shop = [
      "shop"
    ]
    let doctors = [
      "doctors"
    ]
    let exports = [
      "exports"
    ]
    let consultations = [
      "consultations"
    ]

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) && "mm-active"}`}>
              <Link className="ai-icon" to="/" >
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${tests.includes(path) && "mm-active"}`}>
              <Link className="ai-icon" to="/tests" >
                <i className="flaticon-381-television"></i>
                <span className="nav-text">Reports</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/users">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text">Users</span>
              </Link>
            </li>
            <li className={`${consultations.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="consultations" >
                <i className="flaticon-381-heart"></i>
                <span className="nav-text">Consultations</span>
              </Link>
            </li>
            <li className={`${kiosks.includes(path) ? "mm-active" : ""}`} >
              <Link className="ai-icon" to="kiosks" >
                <SlScreenTablet color="#7E7E7E" style={{ width: "10%", marginRight: "15px" }} />
                <span className="nav-text">Kiosks</span>
              </Link>
            </li>
            <li className={`${doctors.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="doctors" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Doctors</span>
              </Link>
            </li>
            <li className={`${exports.includes(path) ? "mm-active" : ""}`} >
              <Link className="ai-icon" to="exports" >
                <TbFileExport color="#7E7E7E" style={{ width: "10%", marginRight: "15px" }} />
                <span className="nav-text">Exports</span>
              </Link>
            </li>
            <li className={`${shop.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="shop" >
                <Cart height={150} width={150} />
                <span className="nav-text">Shop</span>
              </Link>
            </li>
            <li>
              <LogoutPage />
            </li>
          </MM>

          <div className="copyright">
            <p>
              Made with{" "}
              <span
                className={`${this.state.loveEmoji ? "heart heart-blast" : "heart"
                  }`}
                onClick={() =>
                  this.setState({ loveEmoji: !this.state.loveEmoji })
                }
              ></span>{" "}
              by Sanskritech Smart Solution Pvt Ltd
            </p>
          </div>
        </PerfectScrollbar>
      </div >
    );
  }
}

export default SideBar;
