import React, { useEffect, useState } from "react";
import { GetAllDoctors, } from "../../../services";
import PageTitle from "../../layouts/PageTitle";
import { Card, CardHeader, CardMedia, CardContent, Avatar, Typography, Box, Grid, makeStyles } from '@material-ui/core'

export const Doctor = () => {
     const [doctor, setDoctor] = useState([]);
     const [loading, setLoading] = useState(true);

     useEffect(async () => {
          setLoading(true);
          const doctorDataList = await GetAllDoctors();
          const rows = doctorDataList.map((data, i) => ({ id: i + 1, ...data }));
          setDoctor(rows);
          setLoading(false);
     }, []);
     const classes = useStyles();
     // const handleESanjeevani = () => {

     //      window.location.href = 'https://esanjeevaniopd.in/Register?csid=0';
     // }
     return (
          <div className="col-12">
               <div className="col-12">
                    <PageTitle
                         activeMenu="Doctor Listings"
                         motherMenu="Home"
                         pageContent="Post Details"
                    />
                    {!loading && doctor.length ? (
                         <Grid container style={{ marginBottom: 70 }} >
                              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} onClick={() => window.open("https://esanjeevaniopd.in/Register?csid=0", "_blank")}>
                                   <Card className={classes.root} style={{ height: "480px", marginTop: "13px" }}>
                                        <CardHeader
                                             avatar={
                                                  <Avatar aria-label="recipe" className="bg-primary">
                                                       S
                                                  </Avatar>
                                             }
                                             title={
                                                  <Typography
                                                       variant="subtitle1"
                                                       style={{ textTransform: "capitalize", fontWeight: "600" }}
                                                  >
                                                       E - Sanjeevani
                                                  </Typography>
                                             }

                                        />
                                        <CardMedia
                                             className={classes.media}
                                             image={"https://esanjeevaniopd.in/assets/images/login//doctor.png"}
                                             title={"E-SanjeevaniOPD"}
                                        />
                                        <CardContent>
                                             <Typography
                                                  style={{
                                                       textTransform: "capitalize",
                                                       display: "flex",
                                                       flexDirection: "row",
                                                       flexWrap: "wrap",
                                                       fontWeight: "bolder"
                                                  }}
                                                  variant="body1"

                                             >

                                             </Typography>

                                             <div
                                                  style={{
                                                       display: "flex",
                                                       flexDirection: "row",
                                                       alignItems: "normal",
                                                       lineHeight: "0",
                                                  }}
                                             >

                                             </div>


                                             <Typography
                                                  style={{
                                                       textTransform: "capitalize",
                                                       display: "flex",
                                                       flexDirection: "row",
                                                       fontWeight: "bolder",
                                                       marginTop: 10
                                                  }}
                                                  variant="body1"
                                             >

                                             </Typography>
                                             <Box display="flex" flexDirection="row">

                                             </Box>
                                        </CardContent>
                                   </Card>
                              </Grid>
                              {doctor.map((x, i) => (
                                   <Grid item key={i} xs={12} sm={6} md={4} lg={4} xl={4}>
                                        <Card className={classes.root}>
                                             <CardHeader
                                                  avatar={
                                                       <Avatar aria-label="recipe" className="bg-primary">
                                                            {x.name.charAt(0)}
                                                       </Avatar>
                                                  }
                                                  title={
                                                       <Typography
                                                            variant="subtitle1"
                                                            style={{ textTransform: "capitalize", fontWeight: "600" }}
                                                       >
                                                            {x.name}
                                                       </Typography>
                                                  }
                                                  subheader={

                                                       <Box>
                                                            <Box display="flex">
                                                                 {x.qualification.map((x, i) => (
                                                                      <Typography key={i} style={{ color: "#6b7280" }}>
                                                                           {x}&nbsp;
                                                                      </Typography>
                                                                 ))}
                                                            </Box>
                                                       </Box>
                                                  }
                                             />
                                             <CardMedia
                                                  className={classes.media}
                                                  image={x.imageData}
                                                  title={x.name}
                                             />
                                             <CardContent>
                                                  <Typography
                                                       style={{
                                                            textTransform: "capitalize",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            fontWeight: "bolder"
                                                       }}
                                                       variant="body1"

                                                  >
                                                       specialisation :
                                                  </Typography>

                                                  <div
                                                       style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "normal",
                                                            lineHeight: "0",
                                                       }}
                                                  >
                                                       {x.specialisation.map((x, i) => (
                                                            <Typography key={i} style={{ color: "#6b7280" }}>{x}&nbsp;</Typography>
                                                       ))}
                                                  </div>


                                                  <Typography
                                                       style={{
                                                            textTransform: "capitalize",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            fontWeight: "bolder",
                                                            marginTop: 10
                                                       }}
                                                       variant="body1"
                                                  >
                                                       languages spoken :
                                                  </Typography>
                                                  <Box display="flex" flexDirection="row">
                                                       {x.languagesSpoken.map((x, i) => (
                                                            <Typography key={i} style={{ color: "#6b7280" }}>{x}&nbsp;</Typography>
                                                       ))}
                                                  </Box>
                                             </CardContent>
                                        </Card>{" "}
                                   </Grid>
                              ))}
                         </Grid>

                    ) : (
                         <div className="text-center">
                              {loading && (
                                   <div
                                        id=""
                                        style={{
                                             height: "60vh",
                                             display: "flex",
                                             flexDirection: "row",
                                             justifyContent: "center",
                                             alignItems: "center",
                                        }}
                                   >
                                        <div className="sk-three-bounce">
                                             <div className="sk-child sk-bounce1"></div>
                                             <div className="sk-child sk-bounce2"></div>
                                             <div className="sk-child sk-bounce3"></div>
                                        </div>
                                   </div>
                              )}
                         </div>
                    )}
               </div>
          </div>
     );
};

const useStyles = makeStyles((theme) => ({
     root: {
          maxWidth: 345,
     },
     media: {
          height: 250,
          // paddingTop: '56.25%', // 16:9
     },
     expand: {
          transform: "rotate(0deg)",
          marginLeft: "auto",
          transition: theme.transitions.create("transform", {
               duration: theme.transitions.duration.shortest,
          }),
     },
     expandOpen: {
          transform: "rotate(180deg)",
     },

}));
